import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import {
  CustomTextarea,
  ErrorText,
  role,
  useAppStore,
  useTextAreaHook,
} from "@shared";
import { useRef, useState } from "react";
import { MdAudioFile } from "react-icons/md";
import { BiSolidLike } from "react-icons/bi";
import { BiSolidDislike } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import { useTestHook } from "../hooks/hook";
import { useTestStore } from "../model";

export const TestCreate = () => {
  const { selectTestId, setSelectTestId } = useTestStore();
  const { setError } = useAppStore();
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<null | HTMLInputElement>(null);
  const { text, setText, determineErrors, shortenSpaces, error } =
    useTextAreaHook();
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const { createTest } = useTestHook();

  const handleLike = () => {
    setDislike(false);
    setLike((prev) => !prev);
  };

  const handleDislike = () => {
    setLike(false);
    setDislike((prev) => !prev);
  };

  const handleClear = () => {
    fileRef.current.value = "";
    setFile(null);
    setLike(false);
    setDislike(false);
  };

  const handleSubmit = async () => {
    if (!file) return setError("Please upload audio file");

    const formdata = new FormData();

    if (determineErrors()) return;

    const mode = like || dislike ? role.auditor : role.writer;
    formdata.append("file", file);
    formdata.append("like", String(like));
    formdata.append("dislike", String(dislike));
    formdata.append("text", shortenSpaces(text));
    onClose();
    await createTest(formdata, mode);
  };

  const onClose = () => {
    handleClear();
    setText("");
    setSelectTestId(null);
  };
  return (
    <Modal
      backdrop="blur"
      isOpen={selectTestId === 0}
      onClose={onClose}
      className="dark"
      isDismissable={false}
      size="lg"
      placement="top-center"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white">
              Create test item
            </ModalHeader>
            <ModalBody>
              <ErrorText error={error} />
              <CustomTextarea
                value={text}
                error={error}
                setValue={setText}
                className="!bg-black"
              />
              <div className="relative">
                <Input
                  id="audio-file"
                  type="file"
                  ref={fileRef}
                  onChange={(e) => setFile(e.target.files[0])}
                  className="absolute hidden"
                  accept=".wav,audio/wav,audio/wave,audio/x-wav"
                />
                <Button
                  className="p-0 w-full"
                  size="sm"
                  color={file ? "success" : "default"}
                >
                  <label
                    className="cursor-pointer gap-2 px-2 w-full h-full flex items-center"
                    htmlFor="audio-file"
                  >
                    <MdAudioFile className="text-xl" />{" "}
                    <span>{file ? "File uploaded" : "Upload wav file"}</span>
                  </label>
                  {file && (
                    <MdOutlineClear
                      onClick={handleClear}
                      className="text-8xl px-2"
                    />
                  )}
                </Button>
              </div>
              <div>
                <Button
                  size="sm"
                  isIconOnly
                  variant="light"
                  className="rounded-full"
                  onClick={handleDislike}
                  color={dislike ? "danger" : "default"}
                >
                  <BiSolidDislike className="text-xl" />
                </Button>
                <Button
                  size="sm"
                  isIconOnly
                  variant="light"
                  className="rounded-full"
                  color={like ? "danger" : "default"}
                  onClick={handleLike}
                >
                  <BiSolidLike className="text-xl" />
                </Button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" variant="flat" onPress={onClose}>
                Close
              </Button>
              <Button color="default" onPress={handleSubmit}>
                Create
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
