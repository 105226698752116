import { Fragment, useEffect, useMemo } from "react";
import { datasetStatus, IDataset, transcriptStatus } from "../types";
import {
  AudioPlayer,
  CustomTextarea,
  useAppStore,
  useTextAreaHook,
  waitTime,
} from "@shared";
import { Button } from "@nextui-org/react";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { AiOutlineFullscreen } from "react-icons/ai";
import { useDatasetStore } from "../model";
import { useDatasetHook } from "../hooks/hook";
import { BiSolidDislike, BiSolidLike } from "react-icons/bi";
import { DatasetUndo } from "./undo";
type Props = {
  dataset: IDataset;
  index: number;
};
export const DatasetItem = (props: Props) => {
  const { text, setText, determineErrors, shortenSpaces, error } =
    useTextAreaHook();
  const { setError } = useAppStore();
  const {
    setSelectedDatasetId,
    setDatasetModalId,
    undo,
    setUndo,
    updateTranscript,
  } = useDatasetStore();

  const { confirmDataset, editDataset } = useDatasetHook();

  const activeTranscript = useMemo(() => {
    if (props.dataset.status === datasetStatus.ready)
      return props.dataset.transcripts.find(
        (transcript) => transcript.status === transcriptStatus.ready
      );
    else if (props.dataset.status === datasetStatus.conflict)
      return props.dataset.transcripts.find(
        (transcript) => transcript.status === transcriptStatus.conflict
      );
    else
      return props.dataset.transcripts.find(
        (transcript) => transcript.status === transcriptStatus.pending
      );
  }, [props.dataset]);

  useEffect(() => {
    if (
      props.dataset.status === datasetStatus.new ||
      props.dataset.status === datasetStatus.drafted
    ) {
      setText(props.dataset.text);
    } else {
      activeTranscript && setText(activeTranscript?.body);
    }
  }, [props.dataset]);

  const dislikeDisabled =
    text !== activeTranscript?.body ||
    props.dataset.status !== datasetStatus.conflict;
  const likeDisabled = props.dataset.status !== datasetStatus.conflict;

  //shift + enter update
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      (event.key === "Enter" || event.key === "NumpadEnter") &&
      event.shiftKey
    ) {
      event.preventDefault();
      if (
        props.dataset.status === datasetStatus.new ||
        props.dataset.status === datasetStatus.ready
      ) {
        handleUpdate();
      }
    }
  };

  //update
  const handleUpdate = () => {
    const err = determineErrors();
    if (err) return setError(err);
    if (text === props.dataset.text) return;

    editDataset(
      props.dataset.id,
      shortenSpaces(text),
      props.dataset.status === datasetStatus.ready
    );
  };

  //conflict
  const handleConfirm = (rejected: boolean) => () => {
    if (props.dataset.status !== datasetStatus.conflict) return;
    const err = determineErrors();
    if (err) return setError(err);

    const timeout = setTimeout(() => {
      confirmDataset(props.dataset.id, rejected, shortenSpaces(text));
    }, waitTime);

    setUndo({
      ...undo,
      [props.dataset.id]: {
        timeout,
        endTime: Date.now() + waitTime,
        rejected,
        text: shortenSpaces(text),
      },
    });
  };

  const conflictHeight =
    props.dataset.status === datasetStatus.conflict
      ? "!h-[100px]"
      : " h-[56px]";

  return (
    <div className="flex relative items-center py-2 gap-4">
      <DatasetUndo id={props.dataset.id} />
      <span>{props.index + 1}.</span>
      <div className={`flex-1 ${conflictHeight}`}>
        <CustomTextarea
          disabled={
            props.dataset.status !== datasetStatus.new &&
            props.dataset.status !== datasetStatus.ready &&
            props.dataset.status !== datasetStatus.conflict
          }
          onKeyDown={handleKeyDown}
          error={error}
          value={text}
          className={conflictHeight}
          setValue={setText}
        />
      </div>

      {(props.dataset.status === datasetStatus.drafted ||
        props.dataset.status === datasetStatus.reviewing) &&
        props.dataset.freezedTime && (
          <div className="text-green-700">
            {Math.round((Date.now() - props.dataset.freezedTime) / 60000)} m
          </div>
        )}

      {props.dataset.status !== datasetStatus.new &&
        props.dataset.status !== datasetStatus.drafted &&
        activeTranscript && (
          <div className="flex gap-4">
            <div className=" flex items-center gap-3">
              <Button
                size="sm"
                isDisabled={likeDisabled}
                isIconOnly
                variant="light"
                onClick={handleConfirm(false)}
              >
                <BiSolidLike className="text-xl text-green-500" />
              </Button>
              <span>{props.dataset.amountOfLikes}</span>
            </div>
            <div className=" flex items-center gap-3">
              <Button
                size="sm"
                isDisabled={dislikeDisabled}
                isIconOnly
                variant="light"
                onClick={handleConfirm(true)}
              >
                <BiSolidDislike className="text-red-500 text-xl" />
              </Button>
              <span>{props.dataset.amountOfDislikes}</span>
            </div>
          </div>
        )}
      <AudioPlayer
        disableKeyboardControls
        className="!pt-0"
        src={props.dataset.audio}
      />
      <Button
        onClick={() => setDatasetModalId(props.dataset.id)}
        isIconOnly
        className="rounded-full"
        variant="light"
        size="sm"
      >
        <AiOutlineFullscreen className="text-xl" />
      </Button>
      {(props.dataset.status === datasetStatus.new ||
        props.dataset.status === datasetStatus.ready) && (
        <Fragment>
          <Button
            onClick={handleUpdate}
            isIconOnly
            className="rounded-full"
            variant="light"
            size="sm"
          >
            <RiEdit2Fill className="text-xl" />
          </Button>

          <Button
            onClick={() => setSelectedDatasetId(-1 * props.dataset.id)}
            isIconOnly
            className="rounded-full"
            variant="light"
            size="sm"
          >
            <MdDelete className="text-xl" color="red" />
          </Button>
        </Fragment>
      )}
    </div>
  );
};
