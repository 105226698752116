export interface IWord {
  id: number;
  body: string;
}

export interface IWordStore {
  words: IWord[];
  totalAmount: number;
  setWords(words: IWord[], totalAmount: number): void;

  selectedIds: number[];
  setSelectedIds(selectedWords: number[]): void;
  addToSelectedIds(id: number): void;
  removeFromSelectedIds(id: number): void;

  wordsModal: number | null;
  setWordsModal: (wordsModal: number) => void;
}

export const limit = 60;

export enum modalMode {
  create,
  delete,
  clear,
}

export enum sortValues {
  sortByWord = "body",
  sordById = "id",
}

export enum searchKeys {
  orderDirection = "orderDirection",
  orderBy = "orderBy",
}
