import { ChangeEvent, useEffect, useRef } from "react";

type Props = {
  value: string;
  setValue: (text: string) => void;
  className?: string;
  avtoFocused?: boolean;
  disabled?: boolean;
  error?: string | null;
} & React.InputHTMLAttributes<HTMLTextAreaElement>;

export const CustomTextarea = (props: Props) => {
  const textarea = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target.value;

    if (input !== " " && input.startsWith(" ")) return;

    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;

    if (input.length > props.value.length && selectionStart === selectionEnd) {
      const newChar = input[selectionStart - 1];

      if (/^[a-z'\-* ]$/.test(newChar)) {
        props.setValue(input); // Update state if the character is valid
      } else {
        e.target.value = props.value;

        textarea.current.setSelectionRange(
          selectionStart - 1,
          selectionStart - 1
        );
      }
    } else {
      props.setValue(input);
    }
  };

  // const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
  //   let newValue = event.target.value.toLowerCase();
  //   const currentPos = {
  //     start: event.target.selectionStart ?? 0,
  //     end: event.target.selectionEnd ?? 0,
  //   };

  //   if (newValue[newValue.length - 1] === "-") {
  //     if (prevValue.length > newValue.length) {
  //       newValue = newValue.slice(0, -1);
  //       currentPos.start--;
  //       currentPos.end--;
  //     } else {
  //       newValue = newValue + " ";
  //       currentPos.start++;
  //       currentPos.end++;
  //     }
  //   }

  //   const regex =
  //     /( )|(?:(((?:[a-z]+'?){1,}\*{0,3}(?:[a-z]+'?){0,}$)|((?:[a-z]+'?){1,}\*{0,3}(?:[a-z]+'?){0,} )|(?!- -)(\- )){0,})/;

  //   if (newValue.match(regex)?.[0]?.length < newValue.length) {
  //     const jump = Number(!newValue.includes("  "));

  //     if (prevValue.length > newValue.length) {
  //       newValue =
  //         newValue.slice(0, currentPos.start - 1) +
  //         newValue.slice(currentPos.start, newValue.length);
  //     } else newValue = prevValue;
  //     setPosition({
  //       start: currentPos.start - 1 * jump,
  //       end: currentPos.end - 1 * jump,
  //     });
  //   } else {
  //     setPosition(currentPos);
  //   }

  //   setPrevValue(newValue);
  //   props.setValue(newValue);
  // };

  useEffect(() => {
    if (textarea.current && props.avtoFocused) {
      textarea.current.focus();
    }
  }, [props.avtoFocused]);

  // useEffect(() => {
  //   setPrevValue(props.value);
  // }, [props.value]);

  // useEffect(() => {
  //   if (textarea.current) {
  //     textarea.current.setSelectionRange(position.start, position.end);
  //   }
  // }, [props.value, position, prevValue]);
  return (
    <textarea
      disabled={props.disabled}
      placeholder="text"
      ref={textarea}
      onKeyDown={props.onKeyDown}
      value={props.value}
      onChange={handleChange}
      onPaste={(e) => e.preventDefault()}
      className={`h-[56px]  ${
        Boolean(props.error) ? "text-danger-500" : "text-white"
      } w-full bg-zinc-900 text-sm  p-2 border-none  rounded-lg  outline-none resize-none ${
        props.className
      }`}
    />
  );
};

//   /( )|(?:(((?:[a-z]+'?){1,}\*{0,3}(?:[a-z]+'?){0,}$)|((?:[a-z]+'?){1,}\*{0,3}(?:[a-z]+'?){0,} )|(?!- -)(\- )){0,})/;
