import {
  useDatasetDurationHook,
  useSettingsHook,
  useUsersHook,
} from "@entities";
import { useEffect } from "react";

export const useAdminHook = () => {
  const { getUsers } = useUsersHook();
  const { getAudioDuration } = useDatasetDurationHook();
  const { getSettings } = useSettingsHook();
  useEffect(() => {
    (async function () {
      await getUsers();
      await getSettings();
      await getAudioDuration();
    })();
  }, []);
};
